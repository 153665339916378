.main-footer,
.portal-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 0;

	.contentcontainer {
		padding-bottom: 20px;
	}

	.footercontainer {
		color: $white;
		background: $dblue;

		.footerlabel {
			font-size: 14px;
			font-weight: 300;
			color: $sblue;
		}

		.footerlinks {
			font-weight: normal;
			color: $white;
			text-decoration: none;
			transition: all ease 300ms;

			&:hover {
				color: $orange;
				transition: all ease 300ms;
			}
		}

		.contactinfo {

			p,
			.mailto.footerlinks {
				font-weight: bold;
			}

			a {
				font-weight: normal;
				color: $white;
				text-decoration: none;
				transition: all ease 300ms;

				&:hover {
					color: $orange;
					transition: all ease 300ms;
				}
			}
		}

		.showmap {
			font-weight: bold;
			color: $orange;
		}

		.copyright {
			text-align: center;
			color: #1b578e;

			a {
				color: #1b578e;
			}

			.halcyon {
				color: #00446d;
			}
		}

		.copyrightportal {
			text-align: center;
			color: #1b578e;

			.leaf a {
				font-weight: normal;
				color: $white;
				text-decoration: none;
				transition: all ease 300ms;

				&:hover {
					color: $orange;
					transition: all ease 300ms;
				}
			}

			a {
				color: #1b578e;
			}

			span,
			.mailto {
				color: #fff;
			}

			.halcyon {
				font-size: 85%;
				color: #0c243a;
				text-decoration: none;
			}

		}
	}

	.fblink {
		margin: 10px auto;
		transition: all ease 300ms;

		&:hover {
			opacity: .5;
			transition: all ease 300ms;
		}
	}

	.credits {
		.halcyon {
			font-size: 85%;
			color: #0c243a;
			text-decoration: none;
		}
	}
}