/* MOBILE RESPONSIVE
========================================================================== */

/* Custom, iPhone Retina */

@media screen and (min-width: 320px) and (max-width: 767px) {
  /*NAV*/

  .user-menu {
    padding: 0;

    .headertop {
      float: left;
    }

    .navbar-toggle {
      background: $white;

      .icon-bar {
        background: $dblue;
      }
    }

    .navbar-header {
      .navbar-brand img {
        margin: 0 auto;
      }
    }

    .nav {
      .leaf {
        a {
          text-align: center;
          overflow: hidden;
        }

        .authbtn {
          border-left: none;
          text-align: left;
        }
      }
    }

    .hcontactnum {
      margin-top: 40px;

      .cstm {
        float: left;
        margin: 0;
      }

      .nav p,
      .nav .authbtn {
        padding: 0 15px;
      }

      .nav .authbtn img {
        float: left;
      }
    }

    .portal-menu {
      .navbar-left {
        padding: 0;

        .leaf:first-child a {
          padding-left: 0;
        }
      }

    }
  }

  .user-menu.fixtop {
    box-shadow: none;
  }

  .user-menu.mtop50 {
    margin-top: 0;
  }

  #admin-menu {
    padding: 10px;
    margin-bottom: 0;
  }

  #admin-menu {
    position: relative;
  }

  #app,
  #app.mtop192 {
    margin-top: 0;
  }

  .user-menu.fixtop {
    position: relative;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: 6;
  }


  .mobile-text {
    display: block;
  }

  .content {
    display: none;
  }

  .about {
    background: url(/img/mobile-accent.png);
    background-position: top right;
    background-repeat: no-repeat;

    .details {
      width: 100%;

      p {
        font-size: 16px;
      }
    }
  }

  /*WHY VANTAGE*/
  .whychoosevantage {
    .whychoose {
      .item {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  /*OUR TRACK RECORD*/
  .our-track-record {
    .trackrec {
      .item {
        margin: 0 0 50px 0;

        .item-body {
          .item-title {
            margin: 0;
          }
        }
      }
    }
  }


  /*CONTACT US*/
  .contact {

    .recapcha {
      margin-top: 25px;
    }

    .btndiv {
      margin-bottom: 50px;
      padding: 0;
      float: none !important;
    }

    .maps {

      /*padding: 0;*/
      .floating-address {
        display: none;
        width: 290px !important;
      }

      .mobile-address {
        display: block;
      }
    }
  }

  /*FOOTER*/
  .main-footer {
    text-align: center;

    .fb_iframe_widget {
      margin-top: 15px;
      transform: scale(0.77);
      transform-origin: 0 0;
    }

  }

  .datepicker-dropdown {
    top: 17% !important;
    z-index: 0 !important;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .datepicker-dropdown {
    top: 15% !important;
    z-index: 0 !important;
  }
}

/* Small Devices, Tablets */


@media only screen and (min-width: 768px) and (max-width: 1199px) {

  .user-menu .hcontactnum,
  .user-menu .portal-menu {
    margin-top: 30px;
  }

  .user-menu {
    .collapse {
      padding: 15px 0;

      .nav {
        .leaf {

          a,
          button {
            padding: 5px;
          }
        }
      }
    }
  }

  /*ABOUT*/
  .mobile-text {
    display: none;
  }

  .content {
    display: block;
  }

  .about.contentcontainer {
    padding-top: 60px;
  }


  /*LOADING*/
  .processing-page-load {
    overflow: hidden;
    background-color: #FFF;

  }

  .page-loading {
    display: none;
    position: fixed;
    left: 0;
    top: 40%;
    width: 100%;
    text-align: center;
    z-index: 3;
  }

  .page-loading p {
    letter-spacing: .05ex;
    font-weight: 300;
    color: #676767;
    margin: 10px 0;
  }

  .contact {
    .btndiv {
      padding: 0;
      float: none !important;
    }
  }

  #admin-menu {
    position: relative;
    padding: 10px 0;
    margin-bottom: 0;
    border-radius: 0;
  }

  #app,
  #app.mtop192 {
    margin-top: 0;
  }

  .user-menu.fixtop {
    position: relative;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: 6;
  }

  .user-menu.mtop50 {
    margin-top: 0;
  }

}


@media only screen and (min-width: 1366px) and (max-width: 1919px) {
  .datepicker-dropdown {
    top: 31% !important;
    z-index: 0 !important;
  }
}

@media only screen and (min-width: 1920px) {
  .datepicker-dropdown {
    top: 34.6% !important;
    z-index: 0 !important;
  }
}