.user-menu {
	margin: 0;
	border: none;

	.main-nav {
		padding-top: 20px;

		a {
			transition: all ease 300ms;

			&:hover {
				color: $orange;
				transition: all ease 300ms;
			}
		}
	}

	.hcontactnum {
		.nav {
			border: 3px solid transparent;

			.authbtn {
				padding: 15px;
				color: $white;
				border-left: 1px solid $white;
				transition: all ease 300ms;

				&:hover,
				&:focus {
					color: $white;
					background-color: $orange;
					transition: all ease 300ms;
				}

				img {
					margin: 2px 0 0 10px;
					float: right;
				}
			}

			a {
				&:hover {
					border-color: $orange;
				}
			}

			p {
				margin: 0 10px 0 0;
				padding: 15px;
			}
		}
	}

	.hcontactnum,
	.portal-menu {
		margin-top: 20px;
		background-color: $dblue;

		.container {
			padding: 0 15px;
		}
	}

	.portal-menu {
		.nav {
			.authbtn {
				padding: 15px;
				color: $white;
				border-left: 1px solid $white;
				transition: all ease 300ms;

				&:hover,
				&:focus {
					color: $white;
					background-color: $orange;
					border-color: $orange;
					transition: all ease 300ms;
				}

				img {
					margin: 2px 0 0 10px;
					float: right;
				}
			}

			a,
			button {
				padding-top: 15px;
				padding-bottom: 15px;
				border-bottom: 3px solid transparent;
				font-size: 14px;
				font-weight: normal;
				color: $white;
				transition: all ease 300ms;

				&:hover {
					color: $orange;
					border-bottom: 3px solid $orange;
					background-color: transparent;
					transition: all ease 300ms;
				}

				&:active,
				&:focus {
					box-shadow: none;
					outline: none;
					background-color: transparent;
				}
			}

			a.active {
				border-bottom: 3px solid $orange;
				background-color: transparent;
			}

			.dropdown {

				button {
					background-color: transparent;
					border: transparent;

					&:hover,
					&:active {
						border-bottom: 3px solid $orange;
						color: $orange;
						box-shadow: none;
						outline: none;
					}

					&:focus {
						border-bottom: none;
						color: $orange;
						box-shadow: none;
						outline: none;
					}

				}

				.dropdown-menu {
					padding: 0;
					border-radius: 0;
					background-color: #002854;
					top: 105%;

					a {
						padding-top: 10px;
						padding-bottom: 10px;
						font-size: 14px;
						font-weight: normal;
						color: $white;
						border: none;
						transition: all ease 300ms;

						&:hover {
							border: none;
							color: $white;
							background-color: #00588e;
							transition: all ease 300ms;
						}

					}

					a.active {
						border: none;
						color: $white;
						background-color: #00588e;

					}
				}
			}

			.dropdown.open {
				border-bottom: 3px solid $orange;

				a {
					background-color: transparent;
					border: none;
				}
			}

		}
	}

	// .portal-menu {
	// 	margin-top: 20px;

	// }
	// .container {
	// 	padding: 10px 15px;

	// 	.navbar-header {
	// 		.navbar-brand {
	// 			padding: 0 15px;
	// 		}
	// 	}
	// 	.collapse {
	// 		.nav {
	// 			.leaf {
	// 				a {
	// 					transition: all ease 300ms;
	// 					&:hover {
	// 						color: $orange;
	// 						transition: all ease 300ms;
	// 					}

	// 				}
	// 				&:last-child a{
	// 					padding-right: 0;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

#admin-menu {
	background-color: #010101;
	position: fixed;
	top: 0;
	width: 100%;
	padding: 8px;
	border-radius: 0;
	z-index: 1;

	a {
		transition: all ease 300ms;
	}
}

#app {
	margin-top: 145px;
}

#app.mtop192 {
	margin-top: 192px;
}

.user-menu.fixtop {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: $white;
	box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
	z-index: 6;
}

.user-menu.mtop50 {
	margin-top: 50px;
}

#admin {
	min-height: 35px;
	padding: 8px;
	height: 50px;
	margin-bottom: 0;
	border: 0;
	border-radius: 0;
	/*position: absolute;*/
	top: 0;
	width: 100%;
	z-index: 1000;
}

#admin-menu .navbar-nav>li>a {
	padding: 6px;
	color: #fff;
}

#admin-menu .navbar-brand {
	padding: 5px 10px;
	height: 100%;
}

#admin-menu .navbar-toggle {
	padding: 0;
	border: none;
}