// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto);
// @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);

// Variables
// @import "variables";

// Bootstrap
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "../../../../node_modules/font-awesome/scss/font-awesome";

// Helpers
// @import "../helpers";

// Global
// @import "global";

// Pages
@import "pages/dashboard";

// Custom Style
@import "common";
@import "custom";
@import "navs";
@import "footer";
@import "mobile-responsive";

// Plugins
@import "plugins/select2-min";
// @import "../plugin/toastr/toastr.scss";
@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.css);
@import "../plugin/bootstrap-datepicker/bootstrap-datepicker.scss";
