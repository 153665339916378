/* COMMON
   ========================================================================== */
body {
  font-family: 'Roboto', sans-serif;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.overlay {
  background: rgba(255, 255, 255, .8);
  width: 100%;
  z-index: 1;
  margin: 0;
  border-bottom: 2px solid #03a64b;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}

.carousel .carousel-inner img {
  min-width: 100%;
  height: 750px;
  object-fit: cover;
}

/*COLORS*/
$white : #fff;
$sblue : #009ce8;
$dblue : #002854;
$orange : #e95124;
$peache : #ede9e6;

/*STYLE*/
html {
  position: relative;
  min-height: 100%;
  // background-color: $white;
}

body {
  max-width: 1920px;
}

.container {
  max-width: 1200px;
}

.mailto {
  color: $white;
  text-decoration: none;
  transition: all ease 300ms;

  &:hover {
    color: $orange;
    text-decoration: none;
    transition: all ease 300ms;
  }
}

.spacer {
  margin-bottom: 50px;
}

.contentcontainer {
  padding: 60px 0;
}

.hcontactnum {
  margin-top: 20px;
  background: $dblue;
  color: $white;

  span {
    margin-top: 8px;
  }
}

.title {
  font-size: 35px;
  text-align: center;
}

.bluelink {
  font-size: 16px;
  font-weight: bold;
  color: $dblue;

  &:hover,
  &:active,
  &:focus {
    border: transparent;
    outline: transparent;
    box-shadow: transparent;
    color: $dblue;
    background: transparent !important;
  }
}

.goblue {
  color: $dblue;
}

.goorange {
  color: $orange;
}

.gowhite {
  color: $white;
}

.bluetitle {
  font-size: 18px;
  font-weight: bold;
  color: $dblue;
}

.orangetitle {
  font-size: 18px;
  font-weight: bold;
  color: $orange;
}

.title {
  margin-bottom: 45px;
  font-size: 35px;
  font-weight: bold;
}

.orangebtn {
  background: $orange;
  font-size: 14px;
  font-weight: bold;
  color: $white;
  text-transform: capitalize;
  line-height: 20px;
  transition: all ease 300ms;

  &:hover,
  &:focus,
  &:active {
    background: $white;
    color: $orange;
    box-shadow: none;
    border: 1px solid $orange;
    outline: transparent !important;
    transition: all ease 300ms;
  }
}

.mobileslogan,
.mobile-text {
  display: none;
}

.script-message {
  padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  top: 30%;
  width: 100%;
  z-index: 3;

  p {
    margin: 0;
    font-weight: bold;
    color: $orange;
  }
}




/* MAP
   ========================================================================== */
.floating-address {
  position: absolute;
  right: 5%;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #999;
  text-align: left;
  font-family: 'Roboto', 'sans-serif';
  padding-left: 10px;
  width: 258px;
  font-size: 13px;
  padding: 5px;

  .address-title {
    font-size: 14px;
    font-weight: bold;
    color: $sblue;
  }
}

/* CLICK TO SCROLL UP
   ========================================================================== */
#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 37px;
  display: none;
  transition: all 0.3s ease;
  z-index: 5;

  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 15px;
    top: 8px;
    font-size: 30px;
    transition: all 0.3s ease;
  }
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);

  i {
    color: #fff;
    top: 5px;
  }
}

/* BANNER
   ========================================================================== */
.banner {
  // height: 841px;
  // width: 100%;
  // background-repeat: no-repeat;
  // background-size: cover;

  .slogan {
    margin-top: 8%;
    text-align: center;
    color: $dblue;

    .bannerslogan {
      font-size: 50px;
      font-weight: bold;
    }

    .large {
      font-size: 80px;
      font-weight: bold;
    }
  }
}

/* MAIN CONTENT
   ========================================================================== */
.about {
  // background: url('img/accent.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;

  .details {
    width: 70%;
    float: left;

    p {
      font-size: 20px;
    }
  }
}

/* WHY VANTAGE
   ========================================================================== */
.whychoosevantage {
  background: $peache;

  .whychoose {

    .item {
      float: left;
      width: 170px;
      margin: 0 29px;

      .imghldr {
        width: 155px;
        height: 155px;
        margin: 0 auto 30px;
        object-fit: cover;
      }

      .item-body {

        .item-title {
          margin-bottom: 25px;
          font-size: 18px;
          font-weight: bold;
        }

        .item-content p {
          font-size: 15px;
        }
      }
    }
  }
}

/* OUR TRACK RECORD
   ========================================================================== */
.our-track-record {
  background: $dblue;

  .trackrec {

    .item {

      .imghldr {
        width: 90px;
        height: 90px;
        margin: 0 auto 30px;
        object-fit: cover;
        text-align: center;
      }

      .item-body {
        text-align: center;

        .item-title {
          margin-bottom: 25px;
          font-size: 40px;
          font-weight: bold;
        }

        .item-content p {
          font-size: 20px;
          color: $white;
        }
      }
    }
  }
}

/* CONTACT
   ========================================================================== */
.api-holders {
  display: none;
}

.fb-api {
  display: none;
}

.contact,
.auth-box {

  .title {
    margin-bottom: 45px;
    font-size: 35px;
    font-weight: bold;

  }

  input,
  textarea {
    border: 1px solid #c4c4c4;
    background: #f4f4f4;
    color: #898989;
    transition: all ease 300ms;

    &:focus {
      border: 1px solid $dblue;
      background: $white;
    }
  }

  .recapcha {
    transform: scale(0.7);
    transform-origin: 0px 0px 0px;
  }

  .btndiv {
    padding-right: 0;
  }

  .mobile-address {
    display: none;
  }

  .goblue {
    font-size: 16px;
  }

  .-text {
    text-decoration: none;
    transition: all ease 300ms;

    &:hover {
      color: $orange;
      transition: all ease 300ms;
    }
  }
}

.bcrumb {
  .breadcrumb {
    margin: 20px 0 20px 0;
    padding: 0;
    background-color: transparent;

    li a {
      font-size: 12px;
      font-weight: normal;
      color: #878787;
      text-decoration: none;
    }

    .active {
      font-size: 12px;
      font-weight: normal;
      color: #0c2e4f;
    }
  }
}

.select2-dropdown {
  z-index: 0 !important;
}