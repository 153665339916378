// Dashboard Styles
html {
	background-color: #f2f2f2;
}

table.restables-clone {
	display: none;
}

.portal-page.loginasbackend {
	padding-top: 70px;
}

.portal-page.loginas {
	padding-top: 10px;
}

.portal-page {
	background-color: #f2f2f2;

	.panel-body {
		overflow: hidden;
	}

	.contents {
		padding: 20px 0;
	}

	.bcrumb {
		.breadcrumb {
			margin: 0 0 20px 0;
			padding: 0;
			background-color: transparent;

			li a {
				font-size: 12px;
				font-weight: normal;
				color: #878787;
				text-decoration: none;
			}

			.active {
				font-size: 12px;
				font-weight: normal;
				color: #0c2e4f;
			}
		}
	}

	.bluetitle {
		margin: 0;
	}

	.orangetitle {
		margin: 0;
		font-size: 22px;
		font-weight: normal;
		color: #e95124;
	}

	.orangebtn {
		border: transparent;
		width: 115px;
		height: 30px;
		font-size: 12px;
	}

	.select2-container--default {
		.select2-selection--single {
			height: 40px;
			background-color: transparent;

			.select2-selection__rendered {
				font-size: 15px;
				font-weight: bold;
				color: #009ce8;
				line-height: 38px;
			}

			.select2-selection__arrow b {
				margin-left: -18px;
				margin-top: 2px;
				border-width: 8px 8px 0 8px;
				border-color: #e95124 transparent transparent transparent;
				transition: all ease 300ms;

			}
		}
	}

	.select2-container--default.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow b {
				margin-left: -18px;
				margin-top: 2px;
				border-width: 0 8px 8px 8px;
				border-color: transparent transparent #e95124 transparent;
				transition: all ease 300ms;

			}
		}
	}

	.select-desc {
		margin: 10px 0 0 0;
		font-size: 15px;
		font-weight: normal;
		color: #878787;
	}

	.right-content {
		.bluetitle {
			font-size: 15px;
		}
	}

	.divider hr {
		border-color: #d8d8d8;
		margin-top: 10px;
	}

	.panel {
		.orangetitle {
			font-size: 15px;
		}

		.table {
			td {
				// border: transparent;
				font-size: 15px;
				font-weight: normal;
				color: #545454;
			}

			td:nth-child(even) {
				// text-align: right;
			}
		}
	}

	.energy-bulletin,
	.energy-usage {
		.goblue {
			margin: 0;
			font-size: 15px;
			font-weight: bold;
			color: #009ce8;
		}

		small {
			font-weight: bold;
			color: #878787;
		}

		.inner-box {
			padding: 0 10px;

			.header {
				margin-bottom: 10px;
			}
		}

		.panel {
			margin-bottom: 25px;
			padding: 15px;

			.panel-heading {
				padding: 0 0 20px 0;
				border: 1px solid transparent;
				background-color: transparent;

				.fa {
					margin-right: 20px;
					font-size: 24px;
					color: #0066a5;
				}

				small {
					font-size: 12px;
					font-weight: bold;
					color: #939393;
				}
			}

			.panel-body {
				padding: 0;

				.basic {
					font-size: 13px;
					font-weight: normal;
					color: #545454;
				}
			}
		}

		.table {
			td {
				border-color: #d8d8d8;
			}
		}
	}

	.bill-view {
		.panel {
			.bluetitle {
				margin-top: 15px;
				font-size: 15px;
			}

			.table-responsive {
				.table {
					td {
						border: transparent;
						font-size: 14px;
						font-weight: normal;
						color: #0c2e4f;
					}

					.orangetitle {
						font-size: 15px;
						font-weight: bold;
						color: #e95124;
					}

					.orangebtn {
						width: 115px;
						height: 30px;
						font-size: 12px;

					}

					.viewbtn {
						height: 30px;
						font-size: 12px;
					}
				}

			}
		}
	}

	.bill-box {
		.bluetitle {
			margin-top: 15px;
			font-size: 15px;
		}
	}

	.profile {
		.profile-pic {
			img {
				width: 135px;
				height: 135px;
				margin: 0 auto;
				object-fit: cover;
				border-radius: 67px;
			}
		}

		.table {
			tr:nth-of-type(even) {
				background-color: #f9f9f9;
			}

			tr:nth-of-type(odd) {
				background-color: #fff;
			}

			th {
				border: transparent;
				font-size: 15px;
				font-weight: normal;
				color: #0c2e4f;
			}

			td {
				padding-left: 5%;
				border: transparent;
				font-size: 15px;
				font-weight: normal;
			}
		}

		.editbtn {
			margin-bottom: 40px;
		}

		.orangebtn {
			width: auto;
		}

		.account-list {
			thead {
				margin-bottom: 40px;
			}

			th {
				border-bottom: 1px solid #ddd;
			}

			td:nth-child(2) {
				padding-left: 8px;
			}

			.bluetitle {
				font-size: 18px;
				font-weight: bold;
				color: #002854;
			}
		}
	}

	.bill-sum {
		margin-bottom: 0;
	}

	.energy-bulletin {
		.goblue {
			margin: 0;
			font-size: 15px;
			font-weight: bold;
			color: #0066a5;
		}

		label {
			font-size: 15px;
			font-weight: bold;
			color: #0c2e4f;
		}

		form {
			input {
				background-color: #f7f7f7;
			}

			.panel {
				background-color: #e7e7e7;
			}

			.selectbox {
				width: 41%;
				height: 40px;
				border: 1px solid #c6c6c6;
			}

			.by-keyword {
				width: 76%;
				height: 40px;
				font-size: 12px;
				border: 1px solid #c6c6c6;
			}

			.btn-primary {
				padding: 6px 11px;
				border-color: #0c2e4f;
				background-color: #0c2e4f;
				transition: all ease 300ms;

				&:hover {
					border-color: #0c2e4f;
					color: #0c2e4f;
					background-color: #fff;
					transition: all ease 300ms;
				}
			}

			.select2-container--default {
				.select2-selection--single {
					background-color: #f7f7f7;

					.select2-selection__rendered {
						font-size: 12px;
						font-weight: normal;
						color: #6d6d6d;
						line-height: 38px;
					}

					.select2-selection__arrow b {
						margin-left: -18px;
						margin-top: 2px;
						border-width: 6px 6px 0 6px;
						border-color: #0c2e4f transparent transparent transparent;
						transition: all ease 300ms;

					}
				}
			}

			.select2-container--default.select2-container--open {
				.select2-selection--single {
					.select2-selection__arrow b {
						margin-left: -18px;
						margin-top: 2px;
						border-width: 0 6px 6px 6px;
						border-color: transparent transparent #0c2e4f transparent;
						transition: all ease 300ms;

					}
				}
			}
		}
	}

	.energy-bulletin-inner {
		.goblue {
			margin: 10px 0 0 0;
			font-size: 15px;
			font-weight: bold;
			color: #0066a5;
		}

		.bulletin-post {
			padding: 15px;

			.imgth {
				margin-right: 30px;
				width: 541px;
				height: 366px;
				object-fit: cover;
			}
		}
	}

	.bill-message {
		.message {
			margin-top: 20%;
			font-size: 18px;
			font-weight: bold;
			font-style: italic;
			color: #0066a5;
		}
	}

	.pagination {

		a,
		span {
			margin: 0 3px;
			border: transparent;
			border-radius: 3px;
			font-size: 15px;
			font-weight: normal;
			color: #939393;
			transition: all ease 300ms;

			&:hover {
				color: #939393;
				background-color: #d6d6d6;
				transition: all ease 300ms;
			}
		}

		.active {
			span {
				border: transparent;
				font-weight: normal;
				color: #939393;
				background-color: #d6d6d6
			}
		}

		.disabled {
			span {
				border: transparent;
				color: #939393;
				opacity: .5;
			}
		}

	}
}

.about {
	.about-content {
		overflow: hidden;
	}
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #e95124 !important;
	color: #fff !important;
	transition: all ease 300ms;
}

/*LOADING*/
.processing-page-load {
	overflow: hidden;
	background-color: #FFF;

}

.page-loading {
	display: none;
	position: fixed;
	left: 0;
	top: 40%;
	width: 100%;
	text-align: center;
	z-index: 3;
}

.page-loading p {
	letter-spacing: .05ex;
	font-weight: 300;
	color: #676767;
	margin: 10px 0;
}

/* MOBILE RESPONSIVE
========================================================================== */

/* Custom, iPhone Retina */

@media screen and (min-width: 320px) and (max-width: 767px) {
	.user-menu.user-portal-menu {
		margin-bottom: 40px;

		.portal-menu {
			.nav {
				.leaf {
					.authbtn {
						text-align: center;
						width: 100%;

						img {
							display: none;
						}
					}
				}
			}
		}
	}

	.portal-page {
		.orangetitle {
			margin-bottom: 10px;
		}

		.select2 {
			margin-top: 10px;
		}

		.panel {
			canvas {
				margin-bottom: 10px;
			}

			.table td:nth-child(even) {
				text-align: left;
			}
		}

		.energy-bulletin {
			form {

				.selectbox,
				.by-keyword {
					width: 100%;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}
	}

}